import React, { useEffect, useRef } from "react";
import bgImage from "../assets/images/background/cta-one-bg.png";
import mocImage from "../assets/images/mocs/cta-moc-1-1.png";
import lottie from "lottie-web";

const CallToAction = () => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/images/lotties/analytics.json"),
    });
  }, []);

  return (
    <section className="cta-one">
      <img src={bgImage} className="cta-one__bg" alt="alter text" />
      <div className="container">
      <div className="lottiecode img-fluid cta-one__moc xlimg" ref={container}></div>
        <div className="row justify-content-lg-end">
          <div className="col-sm-12 col-lg-6">
            <div className="cta-one__content">            
              <i className="cta-one__icon dimon-icon-data1"></i>
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  <span>Planning & Strategy</span>
                </h2>
              </div>
              <div className="cta-one__text">
                <p>
                  We transform technology in molecules.<br /> Apps developed with 
                  science and engineering for important platforms in the market.
                </p>
              </div>
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-check"></i>Web Development
                </li>
                <li>
                  <i className="fa fa-check"></i>Android Development
                </li>
                <li>
                  <i className="fa fa-check"></i>iOs Development
                </li>
              </ul>
              <a href="https://github.com/MoleculaLab" className="thm-btn">
                <span>GitHub - Portfolio</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CallToAction;
