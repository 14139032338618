import React from "react";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import davidA2 from "../assets/images/resources/david-a2.png";
import vicTor from "../assets/images/resources/vic-1998.png";
import mauroS from "../assets/images/resources/sierra-code.png";
import tefaC from "../assets/images/resources/tefa.png";
import burger from "../assets/images/resources/burger.png";
import "../assets/css/animate.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/font-awesome.min.css";
import "../assets/plugins/dimon-icons/style.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";

const TeamPage = () => {
  return (
    <Container className="teamdiv2" id="scientists">
      <div className="block-title text-center">
        <h2 className="block-title__title">
          Lab
          <span> Scientists</span>
        </h2>
      </div>
      <Row>
        <Col>
          <Card
            style={{
              width: "20rem",
              height: "34rem",
              margin: "20px auto",
              color: "#131549",
            }}
            className="teamdiv"
          >
            <Card.Img className="cardpic" variant="top" src={davidA2} />
            <Card.Body>
              <Card.Title>David Alzate² 👽</Card.Title>
              <Card.Text className="teamtext">
                Software Developer <br />+ Industrial engineer <br />
                I’m currently learning how to enter the matrix
              </Card.Text>
              <div className="footer-widget">
                <div className="site-footer__social">
                  <a
                    href="https://www.linkedin.com/in/jdavidalzate"
                    aria-label="social icon"
                    aria-hidden="true"
                    target="_blank"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                  <a
                    href="https://github.com/illker"
                    aria-label="social icon"
                    aria-hidden="true"
                    target="_blank"
                  >
                    <i className="fa fa-github"></i>
                  </a>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              width: "20rem",
              height: "34rem",
              margin: "20px auto",
              color: "#131549",
            }}
          >
            <Card.Img className="cardpic" variant="top" src={vicTor} />
            <Card.Body>
              <Card.Title>Victor Plaza 🤓</Card.Title>
              <Card.Text className="teamtext">
                Software developer with experience in Linux, MySQL, HTML, CSS,
                JavaScript. Knowledge in Vue Js, React js, Hooks, NodeJs.
              </Card.Text>
              <div className="footer-widget">
                <div className="site-footer__social">
                  <a
                    href="https://www.linkedin.com/in/victor-plaza"
                    aria-label="social icon"
                    aria-hidden="true"
                    target="_blank"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                  <a
                    href="https://github.com/vic-1998"
                    aria-label="social icon"
                    aria-hidden="true"
                    target="_blank"
                  >
                    <i className="fa fa-github"></i>
                  </a>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              width: "20rem",
              height: "34rem",
              margin: "20px auto",
              color: "#131549",
            }}
          >
            <Card.Img className="cardpic" variant="top" src={mauroS} />
            <Card.Body>
              <Card.Title className="teamtitle">Mauricio Sierra 😎</Card.Title>
              <Card.Text className="teamtext">
                Software developer with experience in Relational and Non
                relational Databases, Web Servers and Web Design both FrontEnd
                and BackEnd.
              </Card.Text>
              <div className="footer-widget d-flex justify-content-center">
                <div className="site-footer__social">
                  <a
                    href="https://www.linkedin.com/in/mauriciosierrac"
                    aria-label="social icon"
                    aria-hidden="true"
                    target="_blank"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                  <a
                    href="https://github.com/mauriciosierrac"
                    aria-label="social icon"
                    aria-hidden="true"
                    target="_blank"
                  >
                    <i className="fa fa-github"></i>
                  </a>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              width: "20rem",
              height: "34rem",
              margin: "20px auto",
              color: "#131549",
            }}
          >
            <Card.Img className="cardpic" variant="top" src={burger} />
            <Card.Body>
              <Card.Title className="teamtitle">Burger 🍔</Card.Title>
              <Card.Text className="teamtext">
                I'm a bug chaser, i love to found bugs and play with them. 
              </Card.Text>
              <Card.Text className="teamtext">Thanks for giving me your cookies</Card.Text>
              <div className="footer-widget d-flex justify-content-center">
                <div className="site-footer__social">
                  <a
                    href="https://www.linkedin.com/company/molecula-lab/"
                    aria-label="social icon"
                    aria-hidden="true"
                    target="_blank"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                  <a
                    href="https://github.com/MoleculaLab"
                    aria-label="social icon"
                    aria-hidden="true"
                    target="_blank"
                  >
                    <i className="fa fa-github"></i>
                  </a>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        {/* <Col>
          <Card
            style={{
              width: "20rem",
              height: "34rem",
              margin: "20px auto",
              border: "1px solid",
              color: "#131549",
            }}
          >
            <Card.Img className="cardpic" variant="top" src={tefaC} />
            <Card.Body>
              <Card.Title>Esthepania Carvajal</Card.Title>
              <Card.Text className="teamtext">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Button variant="primary">Go somewhere</Button>
            </Card.Body>
          </Card> 
        </Col> */}
      </Row>
    </Container>
  );
};

export default TeamPage;
