import React from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import linux from "../assets/images/brands/linux-original.svg";
import bash from "../assets/images/brands/gnu_bash-icon.svg";
import c from "../assets/images/brands/c-original.svg";
import python from "../assets/images/brands/python-original.svg";
import js from "../assets/images/brands/javascript-original.svg";
import mysql from "../assets/images/brands/mysql-original.svg";
import html5 from "../assets/images/brands/html5-original.svg";
import css3 from "../assets/images/brands/css3-originalsvg.svg";
import git from "../assets/images/brands/git-scm-icon.svg";
import nginx from "../assets/images/brands/nginx-original.svg";
import nodej from "../assets/images/brands/nodejs-original.svg";
import reactSvg from "../assets/images/brands/react-original.svg";
import gatsbySvg from "../assets/images/brands/gatsbyjs-icon-2.svg";
import nextSvg from "../assets/images/brands/nextjs-3.svg";
import graphQLs from "../assets/images/brands/GraphQL.svg";
import vueJsvg from "../assets/images/brands/Vue-js.svg";
import nuxt from "../assets/images/brands/nuxt.svg";
import bootSvg from "../assets/images/brands/bootstrapsvg.svg";


const Brands = () => {
  const params = {
    slidesPerView: 5,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    // Responsive breakpoints
    breakpoints: {
      1499: {
        slidesPerView: 5,
      },

      991: {
        slidesPerView: 3,
      },

      767: {
        slidesPerView: 3,
      },

      575: {
        slidesPerView: 2,
      },

      376: {
        slidesPerView: 2,
      },

    },
  };

  return (
    <section className="service-one" id="molecules">
    <div className="brand-one">
            <div className="block-title text-center">
        <h2 className="block-title__title">
          Software
          <span> Molecules</span>
        </h2>
      </div>
      
      <div className="container">
        <div className="brand-one__carousel owl-carousel owl-theme">
          <Swiper {...params}>
            <div className="item">
              <img src={linux} alt="" />
            </div>
            <div className="item">
              <img src={bash} alt="" />
            </div>
            <div className="item">
              <img src={c} alt="" />
            </div>
            <div className="item">
              <img src={python} alt="" />
            </div>
            <div className="item">
              <img src={js} alt="" />
            </div>
            <div className="item">
              <img src={mysql} alt="" />
            </div>
            <div className="item">
              <img src={html5} alt="" />
            </div>
            <div className="item">
              <img src={css3} alt="" />
            </div>
            <div className="item">
              <img src={git} alt="" />
            </div>
            <div className="item">
              <img src={nginx} alt="" />
            </div>
            <div className="item">
              <img src={nodej} alt="" />
            </div>
            <div className="item">
              <img src={reactSvg} alt="" />
            </div>
            <div className="item">
              <img src={gatsbySvg} alt="" />
            </div>
            <div className="item">
              <img src={nextSvg} alt="" />
            </div>
            <div className="item">
              <img src={graphQLs} alt="" />
            </div>
            <div className="item">
              <img src={vueJsvg} alt="" />
            </div>
            <div className="item">
              <img src={nuxt} alt="" />
            </div>
            <div className="item">
              <img src={bootSvg} alt="" />
            </div>

          </Swiper>
        </div>
      </div>
    </div>
    </section>
  );
};
export default Brands;
